import { LocalisationHelper } from '@/helpers/LocalisationHelper';

export default (context) => {
  const local = context.app.i18n.locale;
  const localisationHelper = new LocalisationHelper(
    context.$http,
    context.store,
    context.$auth,
    local ? local : 'fr'
  );

  // await localisationHelper.setListLanguages();
  // await localisationHelper.setListCountries();
  localisationHelper.init();
  localisationHelper.setListCountries()
};
