export class LocalisationHelper {
  constructor(http, store, auth, lang = 'en') {
    this.http = http;
    this.store = store;
    this.auth = auth;
    this.languageIsoCode = lang;

    if (lang === 'fr') {
      this.lang = 1;
    } else if (lang === 'de') {
      this.lang = 3;
    } else {
      this.lang = 2;
    }
  }

  async setListLanguages() {
    const response = await this.http.API.get('language');
    if (response.status === 200) {
      const data = response.data;
      this.store.commit('shop/localisation/setLanguages', data);
      return data;
    } else {
      throw new Error(`Unexpected status code: ${response.status}`);
    }
  }

  getLanguages() {
    return this.store.state.shop.localisation.languages;
  }

  async setListCountries() {
    
    try {
      const response = await this.http.API.get(
        `country?LanguageIsoCode=${this.languageIsoCode}`
      );

      const data = response.data;
      this.store.commit('shop/localisation/setCountries', data.ActiveCountries);
      return data;
    } catch (error) {
      return error;
    }
  }

  getCountries() {
    return this.store.state.shop.localisation.countries;
  }

  getCurrencies() {
    return this.store.state.shop.localisation.currencies;
  }

  async getAllCurrencies() {
    try {
      const res = await this.http.API.$get('currencies/list');
      if (Array.isArray(res)) {
        return res;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  }

  getAppCountryIsoCode() {
    // let country = this.store.state.shop.localisation.appCountryIsoCode;

    // let defaultCountryIsoCode = process.env.INIT_COUNTRY_ISOCODE
    //   ? process.env.INIT_COUNTRY_ISOCODE
    //   : 'FR';

    // if (country !== null) {
    //   defaultCountryIsoCode = country.CountryIsoCode;
    // }

    // const contryDefaultIndex = this.getCountries().findIndex(
    //   (item) => item.CountryIsoCode === defaultCountryIsoCode
    // );

    // return this.getCountries()[contryDefaultIndex];

    // return country;

    return this.store.state.shop.localisation.appCountryIsoCode;
  }

  setAppCountryIsoCode(val) {
    this.store.commit('shop/localisation/setAppCountryIsoCode', {
      $auth: this.auth,
      val,
    });
  }

  getAppCurrency() {
    // Get the country data
    // const country = this.setAppCountryIsoCode();

    // if (country) {
    //   const currency = this.store.state.shop.localisation.appCurrency;
    //   if (currency === null) {
    //     // Find the main currency for the country
    //     const countryDefaultIndex = country.CountryCurrencies.findIndex(
    //       (item) => item.IsMainCurrency === true
    //     );

    //     if (countryDefaultIndex === -1) {
    //       // Return a default currency if no main currency is found
    //       return {
    //         IdCurrency: '1',
    //         CurrencyName: 'Euro',
    //         CurrencyIsoCode: 'EUR',
    //         CurrencySign: '€',
    //         CurrencyConversionRate: '1.00',
    //         IsMainCurrency: true,
    //       };
    //     }

    //     // Return the main currency found
    //     return country.CountryCurrencies[countryDefaultIndex];
    //   }

    //   // Return the currently set currency
    //   return currency;
    // }

    // Return default currency if no country is found
    return {
      CurrencyIsoCode: this.store.state.shop.localisation.appCurrencyIsoCode,
      CurrencySign: this.store.state.shop.localisation.appCurrencySign,
    };
  }

  setAppCurrency({ currencyIsoCode, currencySign }) {
    this.store.commit('shop/localisation/setAppCurrency', {
      $auth: this.auth,
      currencyIsoCode,
      currencySign,
    });
  }

  init() {
    this.store.commit('shop/localisation/initialiseStore', this.auth);
  }
}
