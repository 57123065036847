import { CartService } from "@/services/CartService.js";

export const state = () => ({
  IdCart: null,
  Currency: {},
  Country: {},
  Total: {},
  Products: [],
  Discounts: {},
  Shipping: {},
});

export const mutations = {
  setAll(state, { data }) {
    state.IdCart = data.IdCart;
    state.Currency = data.Currency;
    state.Total = data.Total;
    state.Products = data.Products;
    state.Discounts = data.Discounts;
    state.Country = data.Country;
    state.Shipping = data.Shipping;
  },
  setSummary(state, { data }) {
    state.IdCart = data.IdCart;
    state.Currency = data.Currency;
    state.Total = data.Total;
  },
  clear(state) {
    state.IdCart = null;
    state.Currency = {};
    state.Country = {};
    state.Total = null;
    state.Products = [];
    state.Discounts = {};
    state.Shipping = {};
  },
};

export const actions = {
  fetchCartSummary(
    { commit },
    { countryIsoCode, onSuccess = () => {}, onError = () => {} } = {}
  ) {
    const cartService = new CartService({ http: this.$http, auth: this.$auth });
    if (!countryIsoCode) {
      countryIsoCode = this.$localisation.appCountryIsoCode;
    }
    cartService
      .getCartSummary({
        countryIsoCode,
        languageIsoCode: this.$i18n.locale,
        currencyIsoCode: this.$localisation.appCurrency.CurrencyIsoCode,
      })
      .then((data) => {
        if (data.IdCart) {
          commit("setSummary", { data });
        }
        onSuccess(data);
      })
      .catch((err) => {
        onError(err);
      });
  },
  fetchCart(
    { commit },
    { countryIsoCode, onSuccess = () => {}, onError = () => {} }
  ) {
    const cartService = new CartService({ http: this.$http, auth: this.$auth });

    if (!countryIsoCode) {
      countryIsoCode = this.$localisation.appCountryIsoCode;
    }
    cartService
      .getCartAll({
        languageIsoCode: this.$i18n.locale,
        currencyIsoCode: this.$localisation.appCurrency.CurrencyIsoCode,
        countryIsoCode,
      })
      .then((data) => {
        if (data.IdCart) {
          commit("setAll", { data });
        }

        onSuccess(data);
      })
      .catch((err) => {
        onError(err);
      });
  },
  
};
