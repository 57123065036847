export default ({ app }, inject) => {
  function detectMobileOS() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    // Detect iOS
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return 'iOS';
    }
    // Detect Android
    if (/android/i.test(userAgent)) {
      return 'Android';
    }
    // Return null if not detected
    return 'ohter';
  }
  // Inject the function into the app and context
  inject('detectMobileOS', detectMobileOS);
};
