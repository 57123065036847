import { LocalisationHelper } from "@/helpers/LocalisationHelper";

import { LocalisationInject } from "./LocalisationInject";

import { ImageProductSizesInject } from "./ImageProductSizesInject";

import { AppInfo } from "./AppInfo";

const GLOBAL_VARIABLES = {
  cmsUrl: process.env.CMS_URL,
  categoryNuberPerPage: 30,
};

export default (context, inject) => {
  const SETTINGS = {
    get isMobile() {
      return context.store.state.shared.wind.isMobile;
    },
    get isTablette() {
      return context.store.state.shared.wind.isTablette;
    },
  };
  inject("SETTINGS", SETTINGS);

  // const imageProductSizesInject = new ImageProductSizesInject(
  //   "https://d1wwyzjrnptc7x.cloudfront.net/"
  // );
  // inject("productImage", imageProductSizesInject);

  const localisationHelper = new LocalisationHelper(
    context.$http,
    context.store,
    context.$auth
  );
  

  const localisationInject = new LocalisationInject(localisationHelper);
  const localisation = localisationInject.localisation;

  inject("localisation", localisation);

  inject("GLOBAL_VARIABLES", GLOBAL_VARIABLES);

  inject("APP_INFO", new AppInfo().socialMedia);
};
