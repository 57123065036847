export const state = () => ({
  localisationDialogState: false,
  languages: [],
  countries: [],
  currencies: ['USD', 'EUR', 'CHF', 'GBP'],
  appLanguage: null,
  appCountryIsoCode: null,
  appCurrencyIsoCode: null,
  appCurrencySign: null,
});

const APP_LANGUE = 'APP__LANGUE';
const APP_COUNTRY_ISOCODE = 'APP_COUNTRY_ISOCODE';

const APP_CURRENCY_ISOCODE = 'APP_CURRENCY_ISOCODE';
const APP_CURRENCY_SIGN = 'APP_CURRENCY_SIGN';

const setDataToCoockies = (cookies, keyName, value) => {
  cookies.set(keyName, value, {
    path: '/',
    maxAge: 60 * 60 * 24 * 365,
  });
};

export const mutations = {
  toggleDialog(state, val) {
    state.localisationDialogState = val;
  },

  setLanguages(state, val) {
    state.languages = val;
  },

  setCountries(state, val) {
    state.countries = val;
  },

  setAppCountryIsoCode(state, { $auth, val }) {
    state.appCountryIsoCode = val;
    
    $auth.$storage.setUniversal(
      APP_COUNTRY_ISOCODE,
      state.appCountryIsoCode
    );

  },

  setAppCurrency(state, { $auth, currencyIsoCode, currencySign }) {
    state.appCurrencyIsoCode = currencyIsoCode;
    state.appCurrencySign = currencySign;

    $auth.$storage.setUniversal(
      APP_CURRENCY_ISOCODE,
      state.appCurrencyIsoCode
    );
    $auth.$storage.setUniversal(APP_CURRENCY_SIGN, state.appCurrencySign);
  },

  initialiseStore(state, $auth) {
    try {
      let _appCountryIsoCode =
        $auth.$storage.getUniversal(APP_COUNTRY_ISOCODE);

      let _appCurrencyIsoCode =
        $auth.$storage.getUniversal(APP_CURRENCY_ISOCODE);

      let _appCurrencySign = $auth.$storage.getUniversal(APP_CURRENCY_SIGN);

      state.appCountryIsoCode =
        _appCountryIsoCode || process.env.INIT_COUNTRY_ISOCODE;

      state.appCurrencyIsoCode =
        _appCurrencyIsoCode || process.env.INIT_CURRENCY_ISOCODE;

      state.appCurrencySign =
        _appCurrencySign || process.env.INIT_CURRENCY_SIGN;

    } catch (error) {
      console.log('ini error', error);
    }
  },
};
