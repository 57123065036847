import { ProductHelpers } from '@/helpers/ProductHelpers.js';

export const state = () => ({
  currentCategory: null,
  loading: true,
  notFound: false,
  filterOptions: null,
  //products
  products: [],
  totalProduct: 0,
  hasNextPage: true,
  hasPreviousPage: false,
  //filters
  idsAttribute: [],
  prices: [],
  promotion: false,
  orderBy: '',
  error: '',
});

export const mutations = {
  setLoading(state, value) {
    state.loading = value;
  },
  setNotFound(state, { val, msg }) {
    state.notFound = val;
    state.error = msg;
  },
  setProducts(state, data) {
    state.products = data.Products;
    state.totalProduct = data.TotalProduct;
    state.hasNextPage = data.HasNextPage;
    state.hasPreviousPage = data.HasPreviousPage;
  },
  loadMoreProduct(state, data) {
    state.products = [...state.products, ...data.Products];
    state.hasNextPage = data.HasNextPage;
    state.hasPreviousPage = data.HasPreviousPage;
  },
  setCurrentCategory(state, { category }) {
    state.currentCategory = category;
  },
  setFilterOptions(state, { filterOptions }) {
    state.filterOptions = filterOptions;
  },
  init(state) {
    state.products = [];
    state.currentCategory = null;
  },
  setFilters(state, { idsAttribute, prices, promotion }) {
    state.idsAttribute = idsAttribute;
    state.prices = prices;
    state.promotion = promotion;
  },
  addIdAttribute(state, { idAttribute }) {
    state.idsAttribute.push(idAttribute);
  },
  removeIdAttribute(state, { idAttribute }) {
    state.idsAttribute = state.idsAttribute.filter((item) => {
      return Number(item) !== Number(idAttribute);
    });
  },
  clearIdsAttribute(state) {
    state.idsAttribute = [];
  },
  setPrices(state, { prices }) {
    state.prices = prices;
  },
  setInPromotion(state, { promotion }) {
    state.promotion = promotion;
  },
  clearPrices(state) {
    state.prices = [];
  },
  //trie
  setOrderBy(state, { value }) {
    state.orderBy = value;
  },
};
function listToString(list) {
  if (list.length > 0) {
    let text = '';
    list.map((item, index) => {
      if (index !== 0) {
        text += ',';
      }
      text += item;
    });
    return text;
  } else {
    return null;
  }
}
export const actions = {
  async fetchCategory({ commit }, { idCategory, permalink, token }) {
    commit('setNotFound', { val: false, msg: '' });
    const productHelpers = new ProductHelpers(
      this.$http,
      this,
      this.$i18n.locale
    );

    try {
      const response = await productHelpers.category({
        idCategory,
        languageIsoCode: this.$i18n.locale,
        permalink,
        token,
      });
      commit('init');
      const category = response;
      commit('setCurrentCategory', { category });
    } catch (error) {
      commit('setNotFound', { val: true, msg: error.message });
    }
    commit('setLoading', false);
  },
  async fetchProducts(
    { commit },
    {
      idCategory,
      permalink,
      idsAttribute,
      prices,
      promotion,
      orderBy,
      page,
      token,
    }
  ) {
    let offset = (page - 1) * 30;
    const productHelpers = new ProductHelpers(
      this.$http,
      this,
      this.$i18n.locale
    );

    const attributes = listToString(idsAttribute);
    const pricesT = listToString(prices);

    try {
      const params = {
        idCategory,
        languageIsoCode: this.$i18n.locale,
        currencyIsoCode: this.$localisation.appCurrency.CurrencyIsoCode,
        offset,
        filterAttribute: attributes,
        prices: pricesT,
        promotion,
        orderBy,
        permalink,
        token,
      };

      const data = await productHelpers.categoryProducts(params);
      commit('setProducts', data);
    } catch (error) {}
  },
  async fetchMoreProducts(
    { commit },
    { idCategory, permalink, idsAttribute, prices,promotion, orderBy, page, token }
  ) {
    let offset = (page - 1) * 30;

    const productHelpers = new ProductHelpers(
      this.$http,
      this,
      this.$i18n.locale
    );

    const attributes = idsAttribute ? listToString(idsAttribute) : '';
    const pricesT = prices ? listToString(prices) : '';

    try {
      const params = {
        idCategory,
        permalink,
        languageIsoCode: this.$i18n.locale,
        currencyIsoCode: this.$localisation.appCurrency.CurrencyIsoCode,
        offset,
        filterAttribute: attributes,
        prices: pricesT,
        promotion,
        orderBy,
        token,
      };
      const data = await productHelpers.categoryProducts(params);
      commit('loadMoreProduct', data);
    } catch (error) {}
  },
  async fetchFiltersOptions(
    { commit, state },
    { idCategory, permalink, languageIsoCode, currencyIsoCode }
  ) {
    const productHelpers = new ProductHelpers(
      this.$http,
      this,
      this.$i18n.locale
    );

    try {
      const response = await productHelpers.categoryFilterOptions({
        idCategory,
        permalink,
        languageIsoCode,
        currencyIsoCode,
      });
      const filterOptions = response;
      commit('setFilterOptions', { filterOptions });
    } catch (error) {}
  },
};
