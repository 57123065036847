const DE = {
  'lang-en': 'English',
  'lang-fr': 'Französisch',
  'lang-de': 'Deutsch',

  from: 'aus',

  'My account': 'Mein Konto',
  Products: 'Produkte',
  'for women from to': 'für Frauen von {0} bis {1}',

  '{0} products found for « {1} »': '{0} Produkte gefunden für « {1} »',
  Reset: 'Zurücksetzen',
  Filter: 'Filtern',
  'View all {0} products': 'Alle {0} Produkte anzeigen',
  'Sort by ascending price': 'Nach aufsteigendem Preis sortieren',
  'Sort by descending price': 'Nach absteigendem Preis sortieren',

  found: 'gefunden',
  'Last products viewed': 'Zuletzt angesehene Produkte',
  'See my favorites': 'Meine Favoriten anzeigen',
  'See more dresses': 'Weitere Kleider ansehen',
  'Add to Cart': 'In den Warenkorb',
  'Add to favorite': 'Zu Favoriten hinzufügen',
  'Not available': 'Nicht verfügbar',
  "We're sorry, this item is no longer available.":
    'Es tut uns leid,<br>dieser Artikel ist nicht mehr verfügbar.',
  'Not available, notify me when back in stock':
    'Nicht verfügbar, bitte benachrichtigen Sie mich, sobald der Artikel wieder auf Lager ist.',
  'Notify me when back in stock':
    'Benachrichtigen Sie mich, wenn wieder auf Lager',
  Send: 'Schicken',
  'You will like also': 'Sie werden auch mögen',
  'Continue my shopping': 'Setze meinen Einkauf fort',
  'The JMP news': 'Die JMP-Neuheiten',
  'Do you wish to order by phone': 'Möchten Sie telefonisch bestellen',
  'Product association': 'Produkt assoziation',
  'Similar products': 'Ähnliche Produkte',
  'your shopping cart': 'Ihr Warenkorb',
  articles: 'Artikel',
  Total: 'Gesamt',
  Subtotal: 'Zwischensumme',
  Delivery: 'Lieferung',
  'Your delivery': 'Ihre Lieferung',
  'Delivery address': 'Lieferadresse',
  'Total (VAT INCLUDED)': 'Gesamt (inkl. MwSt.)',
  'Please enter or add the discount code here':
    'Bitte geben Sie hier den Rabattcode ein oder fügen Sie ihn hinzu',
  Command: 'Befehl',
  'Your Coupon Code': 'Ihr Gutscheincode',
  Save: 'Speichern',
  'Complete With': 'Komplett mit',
  'My favorites': 'Meine Favoriten',
  'Saved for later': 'Speichern für später',
  'Validate my order': 'Bestätige meine Bestellung',
  'ADD AN ADDRESS': 'ADRESSE HINZUFÜGEN',
  'Edit an address': 'Eine Adresse bearbeiten',
  'First name': 'Vorname',
  'Last name': 'Nachname',
  Address: 'Anschrift',
  'Additional address': 'Adresszusatz',
  'ZIP code': 'PLZ',
  Country: 'Land',
  City: 'Wohnort',
  'Mobile phone': 'Telefon mobil',
  Phone: 'Telefon',
  'Address name': 'Adressname',
  'Set as default delivery address': 'Als Standard-Lieferadresse festlegen',
  'Set as default billing address': 'Als Standard-Rechnungsadresse festlegen',
  'Required fields': 'Benötigte Felder',
  'At least {0} characters': 'Mindestens {0} Zeichen',
  'Length should be 6 to 15': 'Die Länge sollte 6 bis 15 Zeichen betragen',
  "By registering for an account, you agree to our terms of use. Please read our <a href='{0}' class='underline'>privacy policy.</a>":
    "Durch die Konto- Registrierung stimmen Sie unseren Nutzungsbedingungen zu. Bitte lesen Sie unsere <a href='/{0}' class='underline' target='_blank'>Datenschutzrichtlinie.</a>",
  'My Address': 'Meine Adresse',
  'I am new here': 'Ich bin neu hier',
  'Good morning': 'Good morning',
  Login: 'Einloggen',
  "Login with Email/Password don't exists":
    'Login mit E-Mail/Passwort existiert nicht',
  'Good morning': 'Guten Morgen',
  'Email address': 'E-Mail-Adresse',
  Password: 'Passwort',
  'New password': 'Neues Passwort',
  'Confirm password': 'Bestätigen Sie dieses Passwort',
  'Forgot your password': 'Passwort vergessen',
  'Register with an email address':
    'Registrieren Sie sich mit einer E-Mail-Adresse',
  'Or register with my social networks':
    'Oder bei meinen sozialen Netzwerken registrieren',
  'Or connect with my social networks':
    'Oder vernetzen Sie sich mit meinen sozialen Netzwerken',
  'Date of birth': 'Geburtstag',
  'E-mail': 'Email',
  'E-mail address': 'E-Mail Adresse',
  optional: 'optional',
  'Privacy Policy': 'Datenschutzrichtlinie',
  'Terms of use': 'Nutzungsbedingungen',
  'Legal Notice': 'Rechtliche Hinweise',
  'Please select a size': 'Größe wählen',
  'Validate my cart': 'meinen Warenkorb bestätigen',
  'Reserve this product in store': 'Reservieren Sie dieses Produkt im Geschäft',

  'Delivered on': 'Geliefert am',
  'See order': 'Siehe Bestellung',
  Invoice: 'Rechnung',
  'Return an item': 'Rückgabe eines Artikels',
  Invoicing: 'Abrechnung',
  Sending: 'Senden',
  Information: 'Information',
  'Order Date': 'Bestelldatum',
  'Order number': 'Bestellnummer',
  'Sending mode': 'Sendemodus',
  'Payment method': 'Zahlungsmethode',
  'Track my delivery': 'Verfolge meine Lieferung',
  'You have a question': 'Sie haben eine Frage',
  'Order Detail': 'Bestelldetails',
  Quantities: 'Mengen',
  'Download my invoice': 'Laden Sie meine Rechnung herunter',
  'Return requested': 'Rücksendung erbeten',
  'Return type': 'Rückgabetyp',
  'Return status': 'Rückgabestatus',
  'Order Summary': 'Bestellübersicht',
  'Total products': 'Gesamte Produkte',
  'Only {0} left to take advantage of free home delivery':
    'Nur noch {0} übrig, um die kostenlose Lieferung nach Hause zu nutzen',
  'Estimated delivery': 'Voraussichtliche Lieferung',
  '<u>Free delivery from {0}</u> at home and at a pick-up point':
    '<u>Kostenlose Lieferung von {0}</u> zu Hause und an einer Abholstelle',
  'We accept payment methods': 'Wir akzeptieren Zahlungsmethoden',
  'Your delivery adress': 'Ihre Lieferadresse',
  'Create a new delivery address': 'Erstellen Sie eine neue Lieferadresse',
  'Edit this address': 'Bearbeiten Sie diese Adresse',
  'Use another address for billing':
    'Verwenden Sie eine andere Adresse für die Rechnungsstellung',
  'Your billing address': 'Ihre Rechnungsadresse',
  'Your delivery option': 'Ihre Lieferoption',
  'Continue to payment': 'Weiter bezahlen',
  'At home': 'carrierType_Home',
  'In relay point': 'carrierType_RelayPoint',
  'In stores': 'carrierType_Store',
  Edit: 'Modifikator',
  'See all products': 'Alle Produkte anzeigen',

  'Payment method for {0}': 'Zahlungsmethoden für {0}',
  'Payment methods': 'Zahlungsmethoden',
  '100% secure. Your bank may ask you to authorize payment to complete your purchase.':
    '100 % sicher. Ihre Bank kann Sie bitten, die Zahlung zu autorisieren, um Ihren Kauf abzuschließen.',
  'I accept the general sales conditions':
    "Indem ich meine Bestellung fortsetze, bestätige ich, dass ich <a class='underline' href='{terms_link}' target='_blank'>die allgemeinen Verkaufsbedingungen</a> gelesen und akzeptiert habe, sowie meinen Wunsch, mit der Zahlung fortzufahren die Bestellung vor jeder Lieferung.",
  'Credit card': 'Kreditkarte',
  'Secure payment with {0}': 'Sichere Zahlung mit {0}',
  'Pay with': 'Bezahlen mit',
  'You will be redirected to the secure payment site.':
    'Sie werden zur sicheren Zahlungsseite weitergeleitet.',

  News: 'Neuigkeiten',
  'Plus Size Fishion': 'Mode in Übergröße',
  'The brand': 'Die Marke',
  'Our shops': 'Unsere Geschäfte',
  Magazine: 'Zeitschrift',
  Home: 'Zuhause',
  Collection: 'Kollektion',
  Clothes: 'Kleidung',
  'In the heart of the rock': 'Das Herz des Felsen',
  'The origin of the waves': 'Der Ursprung der Wellen',
  'The origin of the waves': 'die Kraft der Rosen',
  'Back to basic': 'Zurück zum Grundlegenden',
  'The power of roses': 'Die Kraft der Rosen',
  Lookbooks: 'Lookbooks',
  FAQ: 'Häufige Fragen',
  Discover: 'Entdecken',
  'See more articles': 'Siehe weitere Artikel',
  'All items': 'Alle Elemente',
  'See the lookbook': 'Siehe lookbook',

  'PAYMENT IN SEVERAL TIMES': 'MEHRMAL BEZAHLEN',
  'Payment 100% secured': '100 % sichere Zahlung',
  'Pay in installments from 150': 'Zahlen Sie in Raten ab 150',
  'Our secure payment methods': 'Unsere sicheren Zahlungsmethoden',
  'Bank card, Klarna 3X free of charge from €150, <br />Paypal, American Express':
    'Bankkarte, Alma in 3 Raten ohne zusätzliche Kosten ab 150 €, <br />Paypal, American Express',
  'Delivery and returns': 'Lieferung und Retoure',
  'Delivery in 2-3 days': 'Lieferung in 2-3 Tagen',
  'Easy returns and exchanges': 'Einfache Rückgabe und Umtausch',
  'Free 2-3 day delivery & Return':
    'Kostenlose Lieferung und Rücksendung innerhalb von 2–3 Tagen',
  'Refund & size exchange <br />Full details here.':
    'Erstattung und Größenumtausch <br />Alle Details hier',
  'Customer Service': 'Kundendienst',
  'Monday, Tuesday, Thursday, Friday (10.30am-1pm / 2pm-5.30pm)':
    'Montag, Dienstag, Donnerstag, Freitag (10:30-13:00 / 14:00-17:30)',
  'Receive our news in preview and take advantage of our exclusive offers!':
    'Erhalten Sie unsere Neuigkeiten in der Vorschau als Erstes und profitieren Sie von unseren exklusiven Angeboten !',
  Register: 'Registrieren',
  'Let’s stay connected': 'Bleiben wir in Verbindung',

  'Frequently Asked Questions': 'Häufige Fragen',
  'Delivery & Returns': 'Lieferung & Retoure',
  'Track my order': 'Verfolge meine Bestellung',
  'Follow-up of my order outside France':
    'Nachverfolgung meiner Bestellung außerhalb Frankreichs',
  GTC: 'AGB',
  'Legal Notice': 'Rechtliche Hinweise',
  Privacy: 'Datenschutz',
  'Cookie settings': 'Cookie-Einstellungen',
  'Join us': 'Mach mit',
  Manifesto: 'Manifest',
  'Our commitments': 'Unsere Verpflichtungen',
  'The Diary': 'Das Tagebuch',
  Lookbooks: 'Lookbooks',
  '89 Rivoli street, 75001 Paris': '89 Rivoli-Straße, 75001 Paris',
  '95 Saint-Lazare street, 75009 Paris': '95, Saint-Lazare-Straße, 75009 Paris',
  '92 Alesia street, 75 014 Paris': '92 Alésia-Straße, 75 014 Paris',
  '57 Poland Street, 78100 Saint Germain-en-Laye':
    '57 Polenstraße, 78100 Saint Germain-en-Laye',
  '2 Jean Roisin street, 59 800 Lille': '2 Jean-Roisin-Straße, 59 800 Lille',
  CGV: 'CGV',
  HELP: 'HILFE',
  ABOUT: 'ÜBER',
  'Need help': 'Brauchen Sie Hilfe',
  page_faq_disc:
    'Eine Frage zu Ihrer Bestellung, deren Lieferung, den möglichen Zahlungsmethoden oder sogar Rücksendungen, Umtausch und Rückerstattungen, die Antwort auf Ihre Fragen finden Sie sicherlich in unseren FAQ und ansonsten können Sie uns kontaktieren',

  'My profile': 'Mein Profil',
  'My information': 'Meine Informationen',
  'My addresses': 'Meine Adressen',
  Disconnect: 'Trennen',
  'My orders': 'Meine Bestellungen',
  'my shopping carts': 'meine Warenkörbe',
  'My returns': 'Meine Rückkehr',
  'My favorites': 'Meine Favoriten',

  'Default Delivery': 'Standardzustellung',
  'Default Billing': 'Standardabrechnung',
  'Edit Address': 'Adresse bearbeiten',
  'Delete this address': 'Diese Adresse löschen',

  'My loyalty program': 'Das Programm',
  'My Membership': 'Nehmen Sie am Programm teil',
  'My advantages': 'Punkte bekommen',
  'My history': 'Historisch',
  'Join for free': 'Kostenlos beitreten',
  'Use my points': 'Nutzen Sie meine Punkte',
  'Get the reward': 'Holen Sie sich die Belohnung',
  'MY LOYALTY CARD': 'MEINE TREUEKARTE',
  'LOYALTY CARD': 'TREUEKARTE',
  Yes: 'Ja',
  No: 'Nein',
  loyalty_page_title:
    '<b>CLUB JMP</b> <br /> Das Treueprogramm, von dem Sie profitieren',
  loyalty_page_disc:
    '<b>CLUB JMP</b> ist das Treueprogramm von JMP, bei dem jeder Kunde zählt. Je mehr Sie davon profitieren, desto größer werden Ihre Vorteile. Es ist kostenlos und unverbindlich !',

  'New account': 'Neues Konto',
  'You can create an account in the following steps':
    'Bei den nächsten Schritten können Sie ein Konto einrichten',
  'Continue with guest Checkout': 'Als Gast weiter',

  Monday: 'Montag',
  Tuesday: 'Dienstag',
  Wednesday: 'Mittwoch',
  Thursday: 'Donnerstag',
  Friday: 'Freitag',
  Saturday: 'Samstag',
  Sunday: 'Sonntag',

  January: 'Januar',
  February: 'Februar',
  March: 'März',
  April: 'April',
  May: 'Mai',
  June: 'Juni',
  July: 'July',
  August: 'August',
  September: 'September',
  October: 'Oktober',
  November: 'November',
  December: 'Dezember',

  'Please type your address': 'Bitte geben Sie Ihre Adresse ein',
  'Find an address': 'Adresse finden',
  Search: 'Suche',

  Fullname: 'Vollständiger Name',
  'Card number': 'Kartennummer',
  'Expiry date': 'Ablaufdatum',
  CVC: 'CVC',

  Checkout: 'Zur Kasse gehen',
  'The three-digit code is on the back of the card':
    'Der dreistellige Code befindet sich auf der Rückseite der Karte',
  'This order has already been paid': 'Diese Bestellung wurde bereits bezahlt',

  Preference: 'Präferenz',
  'Make your purchases in': 'Machen Sie Ihre Einkäufe in',
  'Your language': 'Ihre Sprache',
  'Your currency': 'Ihre Währung',
  Currency: 'Währung',
  'Contact language': 'Kontaktsprache',
  'Update preferences': 'Einstellungen aktualisieren',

  'Products in my cart': 'Artikel in meinem Warenkorb',
  account: 'Konto',
  Favorites: 'Favoriten',
  Cart: 'Warenkorb',
  Connection: 'Verbindung',
  'See all my favorites': 'Alle meine Favoriten anzeigen',
  'Free delivery from €150 at home and at a pick-up point. Payment in 3x free of charge from €150':
    '<u>Kostenlose Lieferung ab 150€</u> zu Hause und an einer Abholstelle. Zahlen Sie in 3 kostenlosen Raten ab 150 €',

  Quantity: 'Menge',
  Size: 'Größe',
  'See more': 'Mehr sehen',
  'Filter by': 'Filtern nach',
  Sizes: 'Größen',
  Colors: 'Farben',
  'Price from {0} to {1}': 'Preis von {0} bis {1}',

  '{0} points available': '{0} Punkte verfügbar',
  'My loyalty points': 'Meine Treuepunkte',
  'Total : {0}pts': 'Gesamt: {0} pkt',
  'My point history': 'Meine Punkthistorie',
  'Welcome to your loyalty area.': 'Willkommen im JMP Club',
  'Earn points and enjoy your benefits.':
    'Sammeln Sie Punkte und genießen Sie Ihre Vorteile.',
  'Show my loyalty card': 'Zeigen Sie meine Treuekarte',
  'Redeem my points': 'Meine Punkte einlösen',
  'Get points': 'Sammeln Sie Punkte mit Veranstaltungen',
  modal_event_body:
    'Sobald Sie Ihre neuen Punkte erhalten haben, berücksichtigen Sie das Gültigkeitsdatum Ihrer Punkte, um diese nutzen zu können. Wenn Sie es vergessen, benachrichtigen wir Sie vor Ablauf Ihrer Punkte per E-Mail.',
  'points available': 'Verfügbare Punkte',
  'Buy in store and online': 'Im Geschäft und online kaufen',
  'Earn points by purchasing JMP items and redeem them for discounts.':
    'Sammeln Sie Punkte durch den Kauf von JMP-Artikeln und lösen Sie diese gegen Rabatte ein.',
  'Buy now': 'Kaufe jetzt',
  'Birthday present': 'Geburtstagsgeschenk',
  Birthday: 'Geburtstag',
  'Receive a special gift for your birthday.':
    'Erhalten Sie ein besonderes <br>Geschenk zu Ihrem Geburtstag.',
  points: 'Punkte',
  'Identify yourself in store': 'Identifizieren Sie sich im Geschäft',
  'Present your JMP loyalty card in store. You will find the code in « My Account »':
    'Zeigen Sie Ihre JMP-Treuekarte im Geschäft vor.<br> Sie finden den Code unter « Mein Konto ».',
  'My membership in the {0} loyalty program':
    'Meine Mitgliedschaft im Treueprogramm {0}',
  'Would you like to join the {0} loyalty program?':
    'Meine Mitgliedschaft beim Treueprogramm {0}',
  loyalty_form_text:
    '<p> Nehmen Sie kostenlos am JMP-Treueprogramm teil, um mit jeder Bestellung Punkte zu sammeln und von Rabattcoupons zu profitieren, exklusive Angebote zu erhalten und durch 3 immer großzügiger werdende Status aufzusteigen. Genießen Sie auch alle JMP-Privilegien in Ihren JMP-Shops. </p> <br /> <p> Das JMP Treueprogramm ist jeder natürlichen Person über 18 Jahren vorbehalten </p>',
  'Find out more about the loyalty program':
    'Erfahren Sie mehr über das Treueprogramm',
  'Happy birthday': 'Alles Gute zum Geburtstag',
  'Your points have expired': 'Ihre Punkte sind abgelaufen',
  'Order No': 'Bestell-Nr',
  '{0} points received': '{0} Punkte erhalten',
  '{0} points used': '{0} Punkte verwendet',
  'Loyalty program membership': 'Mitgliedschaft im Treueprogramm',
  'Join the loyalty program': 'Treten Sie dem JMP-Club bei',

  'Type something': 'Schreibe etwas',

  'Or 3x {0} with the <u>payment in 3x free</u>':
    'Oder 3x {0} bei der <u>Einzahlung 3x gratis</u>',
  Color: 'Farbe',
  'Availability in store': 'Verfügbarkeit im Geschäft',
  'Free delivery': 'Gratisversand',
  days: 'Tage',
  'Free return': 'Kostenlose Rücksendung',
  'Secure payment': 'Sichere Bezahlung',
  'free of charge': 'kostenlos',
  Measurements: 'Messungen',
  'How do I take my measurements?': 'Wie nehme ich meine Maße ?',
  'Please refer to the charts below to determine your size':
    'Bitte beachten Sie die folgenden Tabellen, um Ihre Größe zu bestimmen',
  measurements_disc:
    'Nehmen Sie Ihre Maße mit einem Maßband und entnehmen Sie dann der Tabelle unten Ihre Größe. Wenden Sie sich an unseren Kundendienst, wenn Sie die Passform eines bestimmten Artikels unter {0} wissen möchten.',
  'Chest size': 'Oberweite',
  'Place the tape measure at armpit level, where it is strongest, and go around.':
    'Legen Sie das Maßband auf Achselhöhe an, wo es am stärksten ist, und und messen Sie einmal rundherum.',
  'Waist size': 'Taillenumfang',
  'Place the tape measure at the hollow of your waist, below your ribs and above your hips.':
    'Legen Sie das Maßband an der Taillenkehle, unterhalb Ihrer Rippen und oberhalb Ihrer Hüften an.',
  'Hip circumference': 'Hüftumfang',
  'Place the tape measure at the fullest part of your hips.':
    'Legen Sie das Maßband an der breitesten Stelle Ihrer Hüften an.',
  'Size guide': 'Größentabelle',
  Description: 'Beschreibung',
  'Material and fit': 'Material und Schnitt',
  Chest: 'Brust',
  Hips: 'Hüften',
  times: 'mal',
  sofort_disc:
    'SOFORT wurde 2005 in München, Deutschland, gegründet und ist der Nr. 1 Banküberweisungsanbieter in Europa, mit großen Wachstumsplänen im Vereinigten Königreich. Mit einer breiten Marktabdeckung und einer starken Markenbekanntheit gehören wir auch in Deutschland und Österreich zu den Top 3 der meistgenutzten Zahlungsmethoden insgesamt.',
  bancontact_disc:
    'Kleine Beträge ganz einfach bezahlen mit Bancontact: einfach und schnell. Mit der Bancontact-Karte ist es ganz einfach, jeden Betrag zu bezahlen, selbst den kleinsten.',
  ideal_disc:
    'Möchten Sie, dass Ihre Kunden häufiger mit iDEAL bezahlen? Mit ein paar einfachen Änderungen an Ihrer Checkout-Seite können Sie Ihre Kunden ermutigen, schnell und sicher mit iDEAL zu bezahlen.',
  giropay_disc:
    'Bezahlen Sie online mit Giropay – sicher und direkt von Ihrem Girokonto.',
  'Payment in installments with Alma': 'Ratenzahlung mit Alma',
  alma_disc: 'Kostenlose Zahlung in 2, 3 oder 4 Raten.',
  Payment: 'Zahlung',
  Continue: 'Weitermachen',
  'Continue mon shopping': 'Fahren Sie mit dem Einkaufen fort',
  'Continue Shopping': 'Mit dem Einkaufen fortfahren',
  'Free delivery & easy return': 'Kostenlose Lieferung & einfache Rückgabe',
  Next: 'Nächste',
  'To see the availability of this product in stores, please select the desired size.':
    'Um die Verfügbarkeit dieses Produkts in den Geschäften zu sehen, wählen Sie bitte die gewünschte Größe aus.',
  Returns: 'Rendite',
  Loyalty: 'Loyalität',
  back_in_stock_nb:
    'Durch Eingabe Ihrer E-Mail-Adresse erklären Sie sich damit einverstanden, dass Jean Marc Philippe Ihre E-Mail-Adresse verwendet, um Sie über die Verfügbarkeit dieses Produkts zu informieren und Alternativen vorzuschlagen, wenn das Produkt nicht innerhalb von 15 Tagen wieder auf Lager ist. Dieser Bestandsalarm wird nach 15 Tagen gelöscht. Sie haben das Recht, die Verarbeitung Ihrer Daten auf einfache Anfrage an die Datenschutzabteilung (data@jeanmarcphilippe.com) einzusehen, zu ändern oder zu löschen. Weitere Informationen finden Sie in unserer Datenschutzerklärung.',
  back_in_stock_validate:
    "<p> Wir haben Ihre Anfrage erfolgreich erfasst. Sie haben eine Bestätigung per E-Mail an {mail} erhalten. </p> <p>Produkt wieder auf Lager?</p> <ul class='ul-list'> <li> Wir senden Ihnen eine E-Mail eine E-Mail, um Sie darüber zu informieren, wenn dieses Produkt wieder auf Lager ist. </li> </ul>",
  copyright:
    "© {0}, {1} erstellt mit <a href='{2}' class='underline'>Digipart Commerce Cloud</a>",

  order_thanks:
    "<h2> Danke {name}, <br /> für Ihre Bestellung </h2> <p> Vielen Dank für Ihren Einkauf und Ihr Vertrauen Ihnen für Ihr Vertrauen. </p> <p> Wir laden Sie ein, die Details Ihrer Bestellung und Ihre Lieferadresse zu überprüfen. <br /> Wenn Sie einen Fehler bemerken, kontaktieren Sie uns bitte per E-Mail unter <a href='mailto:{mail}' >{mail}</a > oder telefonisch unter <a href='tel:{tel} ' >{tel_text}</a>. </p> <p> <i> Wir informieren Sie per E-Mail über die nächsten Schritte zur Vorbereitung und zum Versand Ihrer Bestellung. Sie können den Status Ihrer Bestellung auch in Ihrem Konto auf jeanmarcphilippe.com auf der Registerkarte <a href='{order_link}'>meine Bestellungen </a> verfolgen. </i> </p>",
  'A question about your order?': 'Eine Frage zu Ihrer Bestellung ?',
  'See the details of my order': 'Siehe Details meiner Bestellung',
  Order: 'Befehl',
  order_thanks_decline: `<h2>BESTELLUNG NICHT ABGESCHLOSSEN!</h2>
  <p>Ihre Bestellung wurde während des Zahlungsvorgangs abgebrochen.</p>
  <p>Wir laden Sie ein, zu <a href='{cart_link}' >Ihrem Warenkorb</a> zurückzukehren, ihn zu überprüfen, Ihre Lieferoption und Ihre Zahlungsmethode auszuwählen, um Ihre Bestellung abzuschließen. </p>
  <p>Wenn Ihnen ein Fehler auffällt, kontaktieren Sie uns bitte per E-Mail unter <a href='mailto:{mail}' >{mail}</a > oder telefonisch unter <a href='tel:{tel}' > {tel_text}</a>.</p>`,
  'See the details of my cart': 'Siehe meinen Warenkorb',
  newsletter_title: '20€ ANGEBOTEN FÜR DIE ERSTE BESTELLUNG',
  newsletter_text:
    'Abonnieren Sie unseren Newsletter und erhalten Sie <b>20€ Rabatt</b> für Ihre erste Bestellung der neuen Kollektion.*',
  newsletter_modal_nb:
    '*Mit der Registrierung Ihrer E-Mail akzeptieren Sie, dass Jean Marc Philippe Ihre E-Mail-Adresse verwendet, um Ihnen Nachrichten per E-Mail zu senden.',
  'thank you for subscribing': 'Danke fürs <br/> Abonnieren !',
  newsletter_confirmation:
    'Eine Bestätigung wurde Ihnen per E-Mail an {mail} gesendet. Sie erhalten in Kürze den JMP-Newsletter.',

  'Create your JMP space for a personalized shopping experience':
    'Erstellen Sie Ihren JPM-Bereich für ein personalisiertes Einkaufserlebnis',
  'No product added to cart': 'Kein Produkt zum Warenkorb hinzugefügt',
  'No favorites added': 'Keine Favoriten hinzugefügt',

  Product: 'Produkt',
  Shop: 'Geschäft',
  'Contact details': 'Kontaktdetails',
  Confirmation: 'Bestätigung',
  Article: 'Artikel',
  'Your contact details': 'Ihre Kontaktdaten',
  Civility: 'Höflichkeit',
  Madam: 'Frau.',
  Mr: 'Herr.',
  'I authorize {0} to inform me of good plans and news':
    'Ich ermächtige {0}, mich über gute Pläne und Neuigkeiten zu informieren',
  Confirm: 'Bestätigen',
  'See the store': 'Siehe den Laden',
  'thank you, for your reservation':
    'Vielen Dank, {name}, <br /> für Ihre Reservierung',
  reservation_confirmes_disc:
    '<p>Die Reservierung ist kostenlos und unverbindlich.</p> <br /> <p> <i> Der Shop bestätigt Ihnen in Kürze die Vormerkung per E-Mail oder Telefon. <u>Warte auf diese Bestätigung</u>, bevor du in den Laden gehst! </i> </p>',
  'Last product in stock': 'Letztes Produkt auf Lager',
  'Out of stock': 'Ausverkauft',
  'See on the map': 'Siehe auf der Karte',
  'Schedules and info': 'Termine und Infos',
  'Choose This Shop': 'Wählen Sie diesen Shop',

  Previous: 'Vorherige',
  'Out Of Stock': 'Ausverkauft',
  'Limited Stock': 'Begrenzter Vorrat',
  'In Stock': 'Auf Lager',
  'Please select a store': 'Bitte wählen Sie einen Shop aus',

  'Create a return': 'Retoure erstellen',
  create_return_confirmed:
    "<h2>Rücksendeantrag bestätigt</h2> <p>Wir haben Ihren Rücksendewunsch erhalten.</p> <p> Nachfolgend finden Sie Ihren Rücksendeschein und Ihr Transportetikett zur Rücksendung Ihrer Bestellung Nr. 37896. Bitte füllen Sie das Formular aus, um es Ihrem Paket beizufügen und verwenden Sie den Transportaufkleber, um es auf Ihr Paket zu kleben. Wenn Sie Fragen zur Rücksendung haben, befolgen Sie die Anweisungen zur Rücksendung. </p> <p> <i> Wir werden Sie über den Erhalt Ihres Pakets informieren, um mit dem Umtausch der Größe oder der angeforderten Rückerstattung fortzufahren. Sie können den Status Ihrer Rücksendung auch in Ihrem Konto auf jeanmarcphilippe.com im Tab <a href='{myReturnsLink}'>meine Rücksendungen </a> verfolgen.. </i> </p>",
  'Documents of your return': 'Dokumente Ihrer Rückkehr',
  Return: 'Rückkehr',
  'Return voucher': 'Rücksendegutschein',
  'Transport label': 'Transportetikett',
  'Explanatory note on returns': 'Erläuterung zu Rücksendungen',
  'Return detail': 'Rücksendedetails',
  Details: 'Einzelheiten',
  'View original order': 'Ursprüngliche Bestellung anzeigen',
  'View Return': 'Rücksendung ansehen',
  'Return Tracking': 'Rückverfolgung',
  'Return request validated': 'Rücksendeanforderung validiert',
  'Return documents available': 'Rücksendedokumente vorhanden',
  'Return parcel sent': 'Rückpaket verschickt',
  'Your points have expired': 'Ihre Punkte sind abgelaufen',
  'Return received, processing in progress':
    'Rücksendung erhalten, Bearbeitung läuft',
  'Return complete': 'Rücksendung komplett',
  Exchange: 'Austausch',
  Refund: 'Erstattung',
  'Select a size to continue': 'Wählen Sie eine Größe aus, um fortzufahren',

  'My returns': 'Meine Rendite',

  'Use at least 6 characters': 'Verwenden Sie mindestens 6 Zeichen',
  'By registering for an account, you agree to our terms of use. Please read our privacy policy.':
    'Indem Sie sich für ein Konto registrieren, stimmen Sie unseren Nutzungsbedingungen zu. Bitte lesen Sie unsere Datenschutzerklärung.',
  'All the questions': 'Alle Fragen',
  FEATURED: 'AUSGEWÄHLT',
  'Reset instructions have been sent to':
    'Anweisungen zum Zurücksetzen wurden an gesendet',
  'You did not receive the email': 'Sie haben die E-Mail nicht erhalten',
  'Check your spam folder': 'Bitte kontrollieren Sie Ihren Spam- Ordner',
  'Check the given email to reset the password':
    'Überprüfen Sie die angegebene E-Mail, um das Passwort zurückzusetzen',
  'Wait a few minutes before trying again, as some requests are slow to process':
    'Warten Sie einige Minuten, bevor Sie es erneut versuchen, da einige Anfragen eine Weile brauchen',
  'See our addresses': 'Siehe unsere Adressen',
  Schedule: 'Zeitplan',
  'Contact us': 'Kontaktieren Sie uns',
  Itinerary: 'Route',
  'Open in map': 'In Karte öffnen',
  'Schedules and info': 'Termine und Infos',
  'other relay points nearby': 'andere Relaispunkte in der Nähe',
  'Choose another relay point': 'Wählen Sie einen anderen Relaispunkt',
  'Your address': 'Deine Adresse',
  Map: 'Karte',
  'Choose this parcel relay': 'Wählen Sie diese Paketweiterleitung',
  'Promo Code / Voucher': 'Aktionscode / Gutschein',
  form_abandoned_nb:
    'Indem Sie Ihre E-Mail-Adresse registrieren, akzeptieren Sie, dass Jean Marc Philippe Ihre E-Mail-Adresse verwendet, um Ihnen während des Kaufabschlusses Produkte zu Ihrem Warenkorb zu senden.',
  'Do you hesitate': 'Zögern Sie',
  'We have registered your request. A confirmation has been sent to you by email at {mail}':
    "Wir haben Ihre Anfrage registriert. Eine Bestätigung wurde Ihnen per E-Mail an <a href='{order_link}'>{mail}</a>",
  'Your promo codes': 'Ihre Promo-Codes',
  'the product you requested no longer exists':
    'das von Ihnen angeforderte Produkt existiert nicht mehr !',
  'This category does not exist': 'Diese Kategorie existiert nicht !',
  "This shop doesn't exist": 'Dieser Shop existiert nicht !',
  'This page does not exist !': 'Diese Seite existiert nicht !',
  Newsletter: 'Newsletter',
  'I would like to receive the {0} newsletter':
    'Ich möchte den {0}-Newsletter erhalten*',

  'My newsletter subscription': 'Mein Newsletter-Abonnement',
  newsletter_nb:
    'Unser Redaktionsteam sendet Ihnen personalisierte Inhalte, um Sie über unsere neuesten Nachrichten, neuen Trends, Kollektionsveröffentlichungen, unsere besten Angebote, Aktionen und Verkäufe zu informieren. Sie können Ihre Einstellungen hier jederzeit ändern.',
  account_news_letter_p1:
    "Ich habe die <a href='{0}' class='underline'>Datenschutz- und Cookie-Richtlinie</a> gelesen und verstanden und bin damit einverstanden, personalisierte Marketingmitteilungen von {1} per E-Mail zu erhalten.",
  account_news_letter_p2:
    'Wenn Sie Ihre Einstellungen ändern, kann es bis zu 7 Tage dauern, bis die Änderungen wirksam werden.',
  'You have successfully registered': 'Sie haben sich erfolgreich registriert',
  'You have successfully unsubscribed': 'Du hast dich erfolgreich abgemeldet',

  'Customer service': 'Kundendienst',
  Assistance: 'Hilfe',
  'My requests': 'Meine Anfragen',
  'Do you need help with a recent article, {0} ?':
    'Benötigen Sie Hilfe bei einem aktuellen Artikel, {0} ?',
  'Select the item below that you need help with or get help with something else':
    "Wählen Sie unten den Artikel aus, zu dem Sie Hilfe benötigen, oder <a href='{faq_url}' class='underline'>holen Sie sich Hilfe für etwas anderes</a>",
  'Required Field': 'Pflichtfeld',
  'Your message': 'Ihre Nachricht',
  'Type your message': 'Geben Sie Ihre Nachricht ein',
  'Send your request': 'Senden Sie Ihre Anfrage',
  'Send your message': 'Senden Sie Ihre Nachricht',
  'Last message': 'Letzte Nachricht',
  Message: 'Nachricht',
  Subject: 'Thema',
  ticket_state__Closed: 'Anfrage bearbeitet',
  ticket_state__InProcess: 'Aktuelle Anfrage',
  ticket_state__Opened: 'Anfrage öffnen',
  'Add message': 'Nachricht hinzufügen',
  'View chat history': 'Chatverlauf anzeigen',
  'Details of the ASSISTANCE request': 'Einzelheiten der UNTERSTÜTZUNG-Anfrage',
  'Your evaluation has been successfully updated':
    'Ihre Bewertung wurde erfolgreich aktualisiert',
  'You can only modify your evaluation within a duration of 24 hours after the evaluation.':
    'Sie können Ihre Bewertung nur innerhalb von 24 Stunden nach der Bewertung ändern.',
  Success: 'Erfolg',
  Error: 'Fehler',
  'See history': 'Siehe Geschichte',
  'ASSISTANCE in progress': 'UNTERSTÜTZUNG läuft',
  estimated_delivery:
    '<b>Voraussichtliche Lieferung am {date}</b>, wenn Sie vor 12:00 Uhr bestellen. <br />Lieferung in <b>{country}</b>',
  estimated_delivery2:
    '<b>Geschätzte Lieferung zwischen {dateMin} und {dateMax}</b>. <br />Lieferung in <b>{Land}</b>',

  Accessories: 'Zubehör',
  'Last chance': 'Letzte Möglichkeit',
  'Legal notice': 'Impressum',

  home_title: 'JMP · Paris  · Jean Marc Philippe - JMP - Jean Marc Philippe',
  home_meta_discription:
    'Mode für Frauen über 42 · Offizielle JMP E-Boutique · Sichere Zahlung · Zufrieden oder erstattet · After-Sales-Service zu Ihren Diensten.',

  order_state_created: 'Erstellt',
  order_state_preparation: 'laufende Vorbereitung',
  order_state_shipped: 'Ausgeliefert',
  order_state_delivered: 'Geliefert',
  order_state_canceled: 'Abgesagt',

  Le: 'UM',
  shops_url: 'geschafte',
  shop_url: 'geschaft',
  faq_livraisons_url: '/content/faq/lieferungen',

  'Page not found': 'Seite nicht gefunden',

  'This product is no longer available in the chosen version or quantity, modify or delete this product':
    'Dieses Produkt ist in der gewählten Version oder Menge nicht mehr verfügbar. Ändern oder löschen Sie dieses Produkt',
  'This product already exists with this size, modify or delete to continue':
    'Dieses Produkt existiert bereits in dieser Größe. Ändern oder löschen Sie es, um fortzufahren',

  'Length should more than {0}': 'Die Länge sollte mehr als {0} betragen',
  'Please input correct email address':
    'Bitte geben Sie die korrekte E-Mail-Adresse ein',

  'I agree': 'Ich stimme zu',
  coockis_bar_text: `Jean Marc Philippe verwendet technische Cookies, um das ordnungsgemäße Funktionieren der Website sicherzustellen, den Inhalt zu personalisieren und Ihnen ein maßgeschneidertes Erlebnis zu bieten. Indem Sie auf „Cookies akzeptieren“ klicken, stimmen Sie der Speicherung aller Cookies auf Ihrem Gerät zu. Wenn Sie dieses Banner schließen, surfen Sie weiterhin ausschließlich mit technischen Cookies. Um mehr über Cookies zu erfahren, können Sie unsere Cookie-Richtlinie in <a class='underline' href='{0}' target='_blank'>unseren Allgemeinen Geschäftsbedingungen</a> lesen.`,
  'Sorted by': 'Sortiert nach',

  'By default': 'Standardmäßig',
  'Ascending price': 'Aufsteigender Preis',
  'Decreasing price': 'Sinkender Preis',
  Newest: 'Neueste',
  Oldest: 'Der älteste',
  'Choose my delivery': 'Wählen Sie meine Lieferung',
  'Summer sales': "Soldes d'été",
  Sales: 'Umsätze',
  'Summer Sales': 'Sommerschlussverkauf',
  'Privacy Policy': 'Datenschutz-Bestimmungen',

  'Please enter an address': 'Bitte geben Sie eine Adresse an',
  'Address line 2 (optional)': 'Adresszeile 2 (freiwillig)',
  'Apartment, building, floor, door code, etc.':
    'Wohnung, Gebäude, Stockwerk, Türcode usw.',
  'Choose the reason for the return':
    'Wählen Sie den Grund für die Rücksendung',
  register_newsletter:
    'Ja, ich möchte die neuesten Nachrichten per E-Mail erhalten und von exklusiven Angeboten und Rabatten profitieren. Sie können sich jederzeit abmelden.',
  register_loyalty:
    "Ja, ich möchte kostenlos am Treueprogramm <a href='{0}' class='underline' target='_blank'> JMP - Privileges </a> teilnehmen, um bei jeder Bestellung Punkte zu sammeln und von Rabattgutscheinen (als Willkommensgeschenk) zu profitieren 250 Punkte oder 10 € Ermäßigung).",

  'A question about returns?': 'Eine Frage zu Retouren ?',

  'Email does not exist': 'Email existiert nicht',
  'There is something not right': 'Da stimmt etwas nicht',

  'Reset your password': 'Setze dein Passwort zurück',
  'Back to login': 'Zurück zur Anmeldung',
  Previous: 'Ehemalig',
  autologin_text:
    'Bitte warten Sie, Sie werden zur Profilseite weitergeleitet...',
  'The password was changed successfully':
    'Das Passwort wurde erfolgreich geändert !',
  unsubscribe: 'Abbestellen',
  'enregistrer sur le téléphone': 'Auf dem Telefon speichern',
  'Use my points': 'Verwende meine Punkte',
  'The statuses': 'Die Status',
  'Turn your points into rewards': 'Verwandeln Sie Ihre Punkte in Prämien',
  'Take advantage of your loyalty program...':
    'Nutzen Sie die Veranstaltungen Ihres Treueprogramms, um Punkte zu sammeln und in höhere Stufen aufzusteigen!',

  'Discover our 3 statuses': 'Entdecken Sie unsere 3 Status',
  'With each of your purchases or events, accumulate points and enjoy rewards!':
    'Sammeln Sie bei jedem Kauf oder Event Punkte und genießen Sie Belohnungen!',
  ESSENTIAL: 'WESENTLICH',
  PREMIUM: 'PRÄMIE',
  'From 0 to 499 points': 'Von 0 bis 499 Punkte',
  'Loyalty offer': 'Treueangebot',
  'Birthday offer': 'Geburtstagsangebot',
  'Invitation to private sales': 'Einladung zu privaten Verkäufen',
  '3x interest-free payment': '3x zinslose Zahlung',
  '1 simple alteration / year offered': '1 einfache Änderung / Jahr kostenlos',
  'And many other surprises': 'Und viele andere Überraschungen',
  'My Membership': 'Mitgliedschaft beitreten',
  '2 simple alterations / year offered':
    '2 einfache Änderungen / Jahr kostenlos',
  EXCEPTION: 'AUSNAHME',
  'From 500 to 1999 points': 'Von 500 bis 1999 Punkte',
  'Morphostyle session by appointment': 'Morphostyle-Sitzung nach Vereinbarung',
  'Invitation to events': 'Einladung zu Veranstaltungen',
  'And many other surprises': 'Und viele andere Überraschungen',
  'From 2,000 points': 'Ab 2.000 Punkten',
  'Unlimited touch-ups offered': 'Unbegrenzte Nachbesserungen angeboten',
  'excluding marked down product': 'ohne herabgesetztes Produkt',
  'Free premium delivery': 'Kostenlose Premium-Lieferung',
  'No minimum purchase': 'Kein Mindesteinkauf',
  'get loyalty points': 'Holen Sie sich Treuepunkte',
  Event: 'Ereignis',
  All: 'Alles',
  available: 'Verfügbar',
  expired: 'Abgelaufen',
  used: 'Gebraucht',
  'Get the reward': 'Erhalte die Belohnung',
  'See the reward': 'Siehe die Belohnung',
  'Expired reward': 'Abgelaufene Belohnung',
  'Expired reward': 'Belohnung verwendet',
  'Only <b>{0} pts</b> left to obtain this reward':
    'Nur noch <b>{0} Punkte</b>, um diese Belohnung zu',
  Close: 'Schließen',
  'Collect points': 'Sammel Punkte',
  'Get rewards': 'Erhalten Sie Belohnungen',
  'GIFT CARD': '{} GESCHENKKARTE',
  'to spend as you wish! Store, website… treat yourself !':
    '{0}, die Sie nach Belieben ausgeben können! Geschäft, Website ... gönnen Sie sich etwas !',
  loyalty_path: 'programm-fidelite',
  points: 'punkte',
  loyalty_reward_congratulations:
    'Herzlichen Glückwunsch {name}! <br /> Die Prämie wird auf Ihrem Konto aktiviert.',
  loyalty_reward_body: `<p>Sie können es jetzt nutzen.</p>
                        <p>
                            <i>
                              Nachfolgend finden Sie Informationen zu Ihrer Prämie und auch
                              auf der Seite „Meine Prämien“ für die Dauer seiner Gültigkeit.
                            </i>
                        </p>`,
  'GIFT CARD': 'GESCHENKKARTE',
  Reward: 'Belohnen',
  Copy: 'Kopieren',
  Value: 'Wert',
  'Valid until': 'Gültig bis',
  'Loyalty program': 'Treueprogramm',
  Discount: 'Rabatt',
  'Get the points': 'Hol die Punkte',
  'Event completed': 'Event durchgeführt',
  'Your cart is empty': 'Ihr Warenkorb ist leer',
  "It looks like you haven't added anything to your cart":
    'Es sieht so aus, als hätten Sie Ihrem Warenkorb nichts hinzugefügt',
  'This product does not exist !': 'Dieses Produkt existiert nicht !',

  'Refer a friend': 'Empfehlen Sie einen Freund',
  refer_path: 'empfehlen-sie-einen-freund',

  refer_stitle_page:
    'Empfehlen Sie Ihre Freunde und erhalten Sie Rabatte auf Ihre Bestellungen. 15€ gratis für Sie und Ihren Freund!',
  refer_step1_title:
    "Genießen Sie <strong>15€ Rabatt*</strong> und auch Ihre Freunde. Jeder gewinnt! <a target='_blank' href='{link}' class='underline'>Wie funktioniert es ?</a>",
  refer_step1_li1: 'Teilen Sie Ihren Empfehlungslink mit Ihren Freunden',
  refer_step1_li2:
    'Wenn sie ihn verwenden, erhalten sie 15€ Rabatt auf ihre erste Bestellung',
  refer_step1_li3:
    'Sobald ihre Bestellung aufgegeben ist, bieten wir auch Ihnen 15€. Es ist einfach!',
  refer_step1_btn_create:
    'ERSTELLEN SIE IHR KONTO UND GENIESSEN SIE 15€ ANGEBOTEN',
  refer_step1_btn_offred: 'GENIESSEN SIE 15€ ANGEBOTEN',
  refer_step1_nb: '*Angebot gültig ab 100€ Einkaufswert.',
  refer_step1_qt: 'Erfahren Sie mehr über das Empfehlungsprogramm',
  refer_step2_title: 'Registrieren Sie sich, um mit dem Teilen zu beginnen',
  refer_step3_title:
    '<strong>{last_name}, profitieren Sie von 15€ Rabatt*<br/></strong> und auch Ihre Freunde. Jeder gewinnt!',
  refer_form_step_link_title:
    'Teilen Sie diesen Link einfach mit Ihren Freunden:',
  refer_form_step_link_nb:
    'Wir haben Ihnen diesen Link auch gesendet, damit Sie ihn leicht an Ihre Freunde weitergeben können.',
  refer_form_step_email_header: '<span>Von:</span> <span>{email} </span>',
  refer_form_step_email_body: `subject=Sichere%20dir%2015%20€%20Rabatt%20auf%20deine%20erste%20Bestellung!&body=Hallo,%0D%0A%0D%0AJMP%20hat%20mir%20einen%20Rabatt%20von%2015%20€%20gegeben,%20den%20ich%20mit%20meinen%20Freunden%20für%20ihre%20erste%20Bestellung%20teilen%20kann.%20Und%20ich%20bekomme%20auch%2015%20€!%20Alle%20gewinnen!%0D%0A%0D%0AVerwende%20diesen%20Link,%20um%20deinen%20Rabatt%20zu%20erhalten:%20{link}`,
  refer_form_step_email_body_html: `<p>Hallo,</p>
                              <p>JMP gewährte mir einen Rabatt von 15 €, den ich mit meinen Freunden für ihre erste Bestellung teilen konnte. 
                              Und auch ich profitiere von 15€. Jeder gewinnt !</p>
                              <p>Wenn Sie den untenstehenden Link verwenden, erhalten Sie 15 € Rabatt auf Ihre erste JMP-Bestellung. Sobald Ihre Einkäufe getätigt sind, 
                              profitiere ich außerdem von einem Rabatt von 15 €. Jeder gewinnt !</p>
                              <p>Hier ist der Link, um von den 15€ zu profitieren: <span>{link}</span></p>`,
  refer_form_step_facebook_title: 'Erhalten Sie 15 € Rabatt bei JMP',
  refer_form_step_facebook_body:
    'JMP hat mir einen Rabatt von 15 € gegeben, den ich mit meinen Freunden für ihre erste Bestellung teilen kann. Und ich bekomme auch 15 €. Alle gewinnen!',
  refer_form_step_btns_copy: 'LINK KOPIEREN',
  refer_form_step_btns_email: 'PER E-MAIL SENDEN',
  refer_form_step_btns_facebook: 'MIT FACEBOOK TEILEN',
  refer_form_step_btns_sms: 'ANGEBOT ÜBER MEINE NACHRICHTEN SENDEN',
  refer_form_copy_title: 'SIE KÖNNEN AUCH EINEN LINK TEILEN:',

  refer_form_step_whatsapp_body: `Hallo,%0D%0A%0D%0AJMP%20hat%20mir%20einen%20Rabatt%20von%2015%20€%20gegeben,%20den%20ich%20mit%20meinen%20Freunden%20für%20ihre%20erste%20Bestellung%20teilen%20kann.%0D%0AUnd%20ich%20bekomme%20auch%2015%20€!%0D%0A%0D%0AAlle%20gewinnen!%0D%0A%0D%0AHier%20ist%20der%20Link:%20{link}`,
  refer_form_step_whatsapp_body_html: `<p>Hallo,</p>
                                  <p>JMP gewährte mir einen Rabatt von 15 €, den ich mit meinen Freunden für ihre erste Bestellung teilen konnte. 
                                  Und auch ich profitiere von 15€. Jeder gewinnt !</p>
                                  <p>Wenn Sie den untenstehenden Link verwenden, erhalten Sie 15 € Rabatt auf Ihre erste JMP-Bestellung. Sobald Ihre Einkäufe getätigt sind, 
                                  profitiere ich außerdem von einem Rabatt von 15 €. Jeder gewinnt !</p>
                                  <p>Hier ist der Link, um von den 15€ zu profitieren: <span>{link}</span></p>`,

  refer_form_step_btns_whatsapp: 'MIT whatsapp TEILEN',

  sponsored_page_title: 'Dein Freund lädt dich ein',
  sponsored_page_stitle:
    'Profitieren Sie von einem Rabatt von 15€*, den Ihr Freund Ihnen anbietet! Sobald Ihre Bestellung bestätigt ist, erhält auch Ihr Freund dank Ihnen einen Rabatt von 15€*.',
  sponsored_step1_title:
    '<strong>Profitieren Sie von einem Rabatt von 15€*</strong> dank Ihres Freundes.',
  sponsored_step1_p:
    'Geben Sie einfach Ihre E-Mail-Adresse unten ein, um Ihren Rabattcode zu erhalten.',
  sponsored_step1_submit: '15€ RABATT ERHALTEN',
  sponsored_step2_title:
    '<strong>Profitieren Sie von einem Rabatt von 15€*</strong> dank Ihres Freundes.',
  sponsored_step2_p:
    'Hier ist Ihr Rabattcode, um einen Rabatt von 15€ auf Ihre erste Bestellung zu erhalten. Dieses Angebot gilt 6 Tage lang, bis {data}. Wir haben Ihnen dieses Angebot auch per E-Mail geschickt.',
  sponsoredForm_cgv:
    '*Ja, ich bin damit einverstanden, mich für das Sponsoringprogramm anzumelden. Mit der Registrierung akzeptieren Sie unsere Nutzungsbedingungen und unser Sponsoringprogramm',
  'learn more': 'Mehr erfahren',
  sponsored_step1_form_nb:
    'Mit Ihrer Registrierung nehmen Sie kostenlos am Sponsoringprogramm teil und erhalten Rabattgutscheine für Ihre nächste Bestellung.',

  Copied: 'Kopiert',

  'This code is already used': 'Dieser Code wird bereits verwendet',
  'This code is disabled': 'Dieser Code ist deaktiviert',

  account_referal_table_title: 'VERFOLGUNG IHRER EMPFEHLUNGEN',
  account_referal_table_disc:
    'Empfehlen Sie Ihren Freunden und erhalten Sie Rabatte auf Ihre Bestellungen. 15€ für Sie und Ihren Freund, sobald er eine Bestellung aufgibt!',
  waiting: 'Warten',
  registred: 'Registriert',
  won: 'Gewonnen',
  'invitation sent': 'EINLADUNG GESCHICKT',
  'order completed': 'BESTELLUNG ABGESCHLOSSEN',
  'guest registered': 'GAST REGISTRIERT',
  Referral: 'EMPFEHLUNG',
  'Refer friends': 'Freunde empfehlen',
  'My referrals': 'Meine Empfehlungen',
  'Track your sponsorships': 'Verfolgen Sie Ihre Empfehlungen',
  'Sponsorships made': 'GEMACHTE EMPFEHLUNGEN',
  'Guest orders': 'GASTBESTELLUNGEN',

  'Discount code applied': 'Rabattcode angewendet',
  'Apply discount code': 'Rabattcode anwenden',

  'Loyalty: reward unlocked': 'Treue: Belohnung freigeschaltet',
  'Discount code': 'Rabattcode',
  'Apply your loyalty discount codes to your basket.':
    'Wenden Sie Ihre Treuerabattcodes auf Ihren Warenkorb an.',

  'My rewards unlocked': 'Meine Belohnungen sind freigeschaltet',
  'My rewards': 'Meine Belohnungen',

  Language: 'Sprache',
  'You currently have no returns': 'Sie haben derzeit keine Retouren',
  'You can create a new return from My Orders.':
    'Sie können unter Meine Bestellungen eine neue Retoure erstellen.',

  'You have no rewards': 'Sammeln Sie Treuepunkte, um neue freizuschalten.',

  'exchanges-and-refunds': 'umtausch-und-ruckerstattung',
  until: 'bis',

  'Secure credit card payment': 'Sichere Kreditkartenzahlung',
  'Simple, fast and reliable, Stripe protects your transactions with cutting-edge technologies. Buy with confidence!':
    'Stripe ist einfach, schnell und zuverlässig und schützt Ihre Transaktionen mit modernsten Technologien. Kaufen mit Vertrauen!',

  'Order as a guest': 'Als Gast bestellen',
  'Guest order': 'Gastbestellung',

  'Return window closed': 'Rückgabefenster geschlossen',

  'Discover our universe': 'Entdecken Sie unser Universum',
  'Our history ; 40 years of fashion': 'Unsere Geschichte ; 40 Jahre Mode',
  "In line with women's curves": 'Entsprechend den weiblichen Kurven',
  'Creation with audacity and passion':
    'Schöpfung mit Kühnheit und Leidenschaft',
  'Responsible manufacturing in France':
    'Verantwortungsvolle Herstellung in Frankreich',
  'Our news in the newspaper': 'Unsere Nachrichten in der Zeitung',

  'by discount': 'nach Rabatt',
  'By category': 'Nach Kategorie',

  'Please complete your address by adding a mobile phone number':
    'Bitte vervollständigen Sie Ihre Adresse, indem Sie eine Mobiltelefonnummer hinzufügen',

  state: 'Zustand',
  'wallet-dialog-text':
    'Legen Sie Ihre Treuekarte im Geschäft vor und sammeln Sie während Ihres Erlebnisses und Ihrer Einkäufe Punkte. Überprüfen Sie Ihren Punktestand und wandeln Sie Ihre Treuepunkte in Rabatte um.',
  'Authentication by Email/Password has failed or has not been provided.':
    'Falsche E-Mail-Adresse oder falsches Passwort',

  'Bank card': 'Bankkarte',
  'Payment by credit card, Google Pay and Apple Pay.':
    'Zahlung per Kreditkarte, Google Pay und Apple Pay.',
  'You will be redirected to PayPal to finalize your payment.':
    'Sie werden zu PayPal weitergeleitet, um Ihre Zahlung abzuschließen.',
  'Payment in 3 installments without fees.':
    'Zahlung in 3 Raten ohne Gebühren.',

  'delivery between': 'Lieferung zwischen {from} und {to}',
  Preorder: 'Vorbestellung',
  preorder_readmore: `<span>Vorbestellung</span>
                      <ul>
                        <li>Profitieren Sie von einem Rabatt von -10 % auf Ihren vorbestellten Artikel.</li>
                        <li>Der Artikel wird versendet, sobald wir ihn auf Lager haben, gemäß der in der Artikelbeschreibung angegebenen Lieferzeit. Wir behalten uns das Recht vor, Änderungen vorzunehmen, und werden Sie in diesem Fall per E-Mail informieren.</li>
                        <li>Die Lieferoptionen können vor der Zahlung ausgewählt werden. Wenn Sie mehrere Artikel bestellen, versenden wir die verfügbaren Artikel sofort in einer ersten Sendung und die vorbestellten Artikel später in einer zweiten Sendung.</li>
                        <li>Ihr Rückgaberecht beginnt ab dem Tag, an dem Sie den Artikel erhalten.</li>
                      </ul>
                      `,

  how_to_earn_points: 'Wie sammelt man Punkte?',
  join_program: 'Treten Sie unserem JMP Connect Programm bei',
  first_250_points_offered: 'Ihre ersten 250 Punkte sind kostenlos',
  welcome_gift: 'Willkommensgeschenk',
  points: 'Punkte',
  discount_text:
    'das entspricht {amount} Rabatt auf Ihren nächsten Einkauf im Geschäft oder auf jeanmarcphilippe.com',
  accumulate_points: 'Punkte sammeln',
  exchange_points:
    'Verwenden Sie Ihre Punkte jederzeit und entscheiden Sie, wie viele Punkte Sie einlösen möchten',
  discount: 'Rabatt',
  checkout: 'Kasse',
  additional_order_points: 'Zusätzlich zu den gesammelten Bestellpunkten',
  birthday: 'Geburtstag',
  birthday_discount_text:
    'Während Ihres Geburtstagsmonats. Einmal jährlich nutzbar, ausgenommen von Aktionen',
  social_media: 'Soziale Medien',
  follow_social_media:
    'Folgen Sie uns auf Instagram, TikTok, Facebook und sammeln Sie Punkte',
  register: 'Registrieren',
  learn_more: 'Mehr erfahren',
  use_your_points: 'IHRE PUNKTE NUTZEN',
  use_points_anytime:
    'Verwenden Sie Ihre Punkte jederzeit und entscheiden Sie, wie viele Punkte Sie einlösen möchten',
  on_website: 'auf jeanmarcphilippe.com',
  connect_to_access_wallet:
    'Melden Sie sich an, um auf Ihr Guthaben auf der Warenkorbseite zuzugreifen.',
  in_stores: 'in Geschäften',
  generate_discount_online:
    'Generieren Sie Ihren Rabatt direkt aus Ihrem Guthaben auf unserer Website',
  awards_catalog_title: 'Belohnungskatalog',
  use_points_choose_reward:
    'Nutzen Sie Ihre Punkte und wählen Sie die Belohnung, die Ihnen am meisten gefällt:',
  reward_types: 'Geschenkkarten, Kinovorstellungen und vieles mehr!',
  gift_card_20_euro: '20€ Geschenkkarte',
  gift_card_20_euro_desc:
    '20€, die Sie nach Belieben ausgeben können! Geschäft, Webseite… gönnen Sie sich etwas!',
  points: 'Punkte',
  get_reward: 'Belohnung erhalten',
  exclusive_rewards_wait: 'EXKLUSIVE BELOHNUNGEN WARTEN AUF SIE',
  all: 'Alle',
  gift_cards: 'Geschenkkarten',
  partner_offers: 'Partnerangebote',
  register: 'Anmelden',
  learn_more: 'Erfahren Sie mehr',
  my_membership: 'Meine Mitgliedschaft',

  join_loyalty_program: 'Treten Sie unserem Treueprogramm bei:',
  first_250_points_offered: 'Ihre ersten 250 Punkte sind kostenlos',
  welcome_gift_250_points:
    'Erhalten Sie 250 Willkommenspunkte, was einem Rabatt von 10€ bei Ihrem nächsten Einkauf im Geschäft oder auf jeanmarcphilippe.com entspricht',
  want_to_know_more: 'Möchten Sie mehr wissen?',
  'You may also like': 'Vielleicht gefällt es Ihnen auch',
  'Open the conversation': 'Öffnen Sie das Gespräch',
  'Contact-us': 'Kontaktieren Sie uns',
  'Evaluate the assistance':"Bewerten Sie die Hilfe",

  'JMP loyalty club': 'JMP Treueprogramm',
  'Only points left until the next reward!':
    'Nur noch <b>{points} Punkte</b> bis zur nächsten Belohnung!',
  Orders: 'Bestellungen',
  Settings: 'Einstellungen',
  'A question ?': 'Eine Frage?',
  'customer service': 'Kundendienst',
  'My status': 'Mein Status',
  'you only have {points} points left to reach {status}':
    'Nur noch <b>{points}</b> Punkte, um den Status <b>{status}</b> zu erreichen.',

  'With each of your purchases or events, earn points and benefit from rewards!':
    'Sammeln Sie bei jedem Ihrer Einkäufe oder Events Punkte und profitieren Sie von Prämien !',

  'Use your points and choose the reward that makes you the happiest! Your rewards can be used on our site and also in stores':
    'Nutzen Sie Ihre Punkte und wählen Sie die Belohnung, die Sie am glücklichsten macht! Ihre Belohnungen können auf unserer Website und auch in Geschäften eingelöst werden.',
  showAlertReturnCostText:
    'Um einen Umtausch oder eine Rückerstattung vorzunehmen, müssen Sie das Paket vorbereiten und ein Rücksendeetikett ausdrucken, um es zu identifizieren. Die Rückgabe zum Umtausch ist ein kostenloser Service. Im Falle einer Rückerstattung für einen Ihrer Artikel kostet dieser Service <b>{price}</b> und die Rückerstattungsfrist beträgt 10 bzw. 15 Werktage ab Erhalt des Rücksendepakets. Siehe die Allgemeinen Verkaufsbedingungen.',
  //Routing -----------------------
  account_profil: '/konto/information',
  referal_path_faq: '/content/faq/einen-freund-sponsern',

  newsletterCheckbox: {
    title: 'JMP Nachrichten',
    email_text:
      'Ja, ich möchte per E-Mail Informationen über die neuesten Nachrichten und exklusive Angebote und Rabatte erhalten. Abmeldung jederzeit möglich.',
    sms_text:
      'Ja, ich möchte per SMS Informationen über die neuesten Nachrichten und exklusive Angebote und Rabatte erhalten. Abmeldung jederzeit möglich.',
  },

  conatctus: {
    confirmedText: `<div>
                      Vielen Dank, dass Sie uns kontaktiert haben! <br />
                      Wir haben Ihre Nachricht erhalten, und unser Team wird sein Bestes tun,
                      um Ihnen so schnell wie möglich zu antworten.
                    </div>
                    <br />
                    <div>Vielen Dank für Ihr Vertrauen, und bis bald!</div>`,
    button: { send_another_message: 'Senden Sie eine weitere Nachricht' },
  },
};

// export {
//   DE
// };

export default async (context, locale) => {
  return await Promise.resolve(DE);
};
