export const state = () => ({
  list: [],
  mainCategorie: { IdCategory: 200 },
});

export const mutations = {
  init(state, list) {
    state.list = list;
  },
  setMainCategory(state, category) {
    state.mainCategorie = category;
  },
};
