export class LocalisationInject {
  constructor(localisationHelper) {
    this.localisationHelper = localisationHelper;

    // this.localisationHelper.init();
  }

  get localisation() {
    const $this = this;
    return {
      get countries() {
        return $this.localisationHelper.getCountries();
      },
      // get appCountry() {
      //   return $this.localisationHelper.getAppCountry();
      // },
      get appCountryIsoCode() {
        return 'FR';
      },
      get appCurrency() {
        return $this.localisationHelper.getAppCurrency();
      },
      get currencySign() {
        return $this.localisation.appCurrency.CurrencySign;
      },
    };
  }
}
