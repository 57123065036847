export class SearchHelper {
  constructor(http) {
    // this.context = context;
    this.http = http;
  }

  async getData({ term, currencyIsoCode, languageIsoCode, page }) {
    let offset = (page - 1) * 30;
    var FormData = require("form-data");
    var formData = new FormData();

    formData.append("Term", term);
    formData.append("CurrencyIsoCode", currencyIsoCode);
    formData.append("LanguageIsoCode", languageIsoCode);
    formData.append("Offset", offset);
    formData.append("Limit", 29);

    const response = await this.http.API.post(`product/search`, formData);
    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error(`Unexpected status code: ${response.status}`);
    }
  }
}
